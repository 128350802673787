import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractService } from '../commons/abstract.service';
import { Message } from '../models/message.model';
import { MessageScheduled } from '../models/message-scheduled.model';

@Injectable({ providedIn: 'root' })
export class MessageService extends AbstractService<Message> {

  getURLBase(): string {
    return '/message';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  override preCreate(obj: Message): void {
    delete obj?._id;
  }
  override preUpdate(obj: Message): void {
  }

  getByAttendance(attendance: string): Observable<Message[]> {
    return super.get(`/byAttendance/${attendance}`);
  }

  getByMessageId(messageId: string): Observable<Message> {
    return super.get(`/messageId/${messageId}`);
  }

}
