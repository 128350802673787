import { Component, Input, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
  selector: 'field-error-component',
  templateUrl: './field-error.component.html',
  styleUrls: ['./field-error.component.css'],
})
export class FieldErrorComponent implements OnInit {
  @Input() field: string
  @Input() label: string
  @Input() formGroup: FormGroup

  constructor() {}

  ngOnInit() {}

  isFieldValid() {
    return (
      this.formGroup.get(this.field).invalid &&
      this.formGroup.get(this.field).touched
    )
  }

  displayFieldCss() {
    return {
      'is-invalid': this.isFieldValid(),
    }
  }

  isFieldInvalidAndDirtyAndTouched() {
    return (
      this.formGroup.get(this.field).invalid &&
      (this.formGroup.get(this.field).dirty ||
        this.formGroup.get(this.field).touched)
    )
  }

  isFieldErrorsRequired() {
    return this.formGroup.get(this.field).errors['required']
  }

  isFieldErrorsMin() {
    return this.formGroup.get(this.field).errors['min']
  }

  isFieldErrorsMax() {
    return this.formGroup.get(this.field).errors['max']
  }

  isFieldErrorsPattern() {
    return this.formGroup.get(this.field).errors['pattern']
  }

  isFieldErrorsPreviousDate() {
    return this.formGroup.get(this.field).errors['invalidPreviousDate']
  }

  isFieldErrorsFutureDate() {
    return this.formGroup.get(this.field).errors['invalidFutureDate']
  }

  isFieldErrors18YearOldDate() {
    return this.formGroup.get(this.field).errors['invalid18YearsOldDate']
  }

  isFieldErrorsEmail() {
    return this.formGroup.get(this.field).errors['email']
  }

  isFieldErrorsCpf() {
    return this.formGroup.get(this.field).errors['cpfNotValid']
  }

  isFieldErrorsRequest() {
    return this.formGroup.get(this.field).errors['requestWrongFormat']
  }
}
