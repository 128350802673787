import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertService, ReportService } from '../../../../public-api';
import { Pager } from '../../../commons/pager';
import { BehaviorSubject } from 'rxjs';

interface ResponsePerformeceAttendanceTypes {
  createdAt: string;
  channel: string;
  department: string;
  contact: string;
  phone: string;
  user: string;
}

@Component({
  selector: 'modal-report-fast',
  templateUrl: './modal-report-contact.component.html',
  styleUrls: ['./modal-report-contact.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalReportContactComponent {
  pager: Pager<ResponsePerformeceAttendanceTypes> = new Pager<ResponsePerformeceAttendanceTypes>({ perPage: 10 });
  messageStatus: any;
  attendaceStatus: any;
  searchTerm = '';
  originalList: any[];
  loadingSpinnerSubject = new BehaviorSubject<boolean>(true);
  loadingSpinner$ = this.loadingSpinnerSubject.asObservable();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _matDialogRef: MatDialogRef<ModalReportContactComponent>,
    private reportService: ReportService,
    private alertService: AlertService,
  ) {

  }

  ngOnInit(): void {
    this.getList();
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.getList();
  }

  getList(): void {
    const statusMessage = [{
      message: 'Contatos por canais',
      type: 'channel'
    }, {
      message: 'Contatos por departamento',
      type: 'department'
    }, {
      message: 'Contatos por atendente',
      type: 'user'
    },
    {
      message: 'Contatos período atual',
      type: 'leadCurrent'
    },
    {
      message: 'Contatos período anterior`',
      type: 'leadPrevious'
    }];

    this.loadingSpinnerSubject.next(true);
    this.messageStatus = statusMessage.find((x) => x.type === this.data.type) || {message: 'Contatos detalhados'};
    this.reportService.getAttendanceCardContact({
      ...this.data, 
      page: this.pager.page, 
      limit: this.pager.perPage
    }).subscribe({
      next: (value: any) => {
        this.pager = value;
        this.originalList = value.list;
      },
      error: () => this.alertService.error('Ops! Ocorreu um erro ao tentar buscar as respostas rápidas. Tente novamente mais tarde.'),
      complete: () => this.loadingSpinnerSubject.next(false),
    });
  }

  onSearch(term: string) {
    this.searchTerm = term;
    this.pager.list = this.pager.list.filter(item => {
      const searchText = term.toLowerCase();
      return (
        item.channel?.toLowerCase().includes(searchText) ||
        item.department?.toLowerCase().includes(searchText) ||
        item.user?.toLowerCase().includes(searchText) ||
        item.contact?.toLowerCase().includes(searchText) 
      );
    });

    if (term.trim() === '') {
      this.pager.list = this.originalList;
    } else {
      this.pager.list = this.pager.list.filter(item => {
        const searchText = term.toLowerCase();
        return (
          item.channel?.toLowerCase().includes(searchText) ||
          item.department?.toLowerCase().includes(searchText) ||
          item.user?.toLowerCase().includes(searchText) ||
          item.contact?.toLowerCase().includes(searchText) 
        );
      });
    }
  }

  formatPhone(phone: string): string {
    if (!phone) return '';
    
    const cleaned = ('' + phone).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{2})(\d{2})(\d{4,5})(\d{4})$/);
    if (match) {
      return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
    }
    return phone;
  }
}