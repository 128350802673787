export enum MessageScheduledStatusEnum {
  WAITING = 'WAITING',
  DONE = 'DONE',
  ERROR = 'ERROR',
  MANUAL = 'MANUAL',
}

export enum MessageContentEnum {
  TEXT = 'TEXT',
  AUDIO = 'AUDIO',
  FILE = 'FILE',
}
