import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractService } from '../commons/abstract.service';
import { Channel, ChannelEmbedded } from '../models/channel.model';

@Injectable({ providedIn: 'root' })
export class ChannelService extends AbstractService<Channel> {

  constructor(private http: HttpClient) {
    super(http);
  }

  getURLBase(): string {
    return '/channel';
  }

  preCreate(obj: Channel): void {
    delete obj?._id;
  }
  preUpdate(obj: Channel): void {
  }

  createFromEmbedded(obj: ChannelEmbedded): Observable<Channel> {
    return super.post(`/createFromEmbedded`, obj);
  }

  actived(id: string): Observable<Channel> {
    return super.get(`/actived/${id}`);
  }

  unactived(id: string): Observable<Channel> {
    return super.get(`/unactived/${id}`);
  }

  getInstanceConnect(id: string): Observable<unknown> {
    return super.get(`/get/instance/connect/${id}`);
  }

  syncInstanceByChannel(id: string): Observable<Channel> {
    return super.get(`/sync/instance/${id}`);
  }

  restartInstanceByChannel(id: string): Observable<Channel> {
    return super.get(`/restart/instance/${id}`);
  }

  disconnectInstanceByChannel(id: string): Observable<Channel> {
    return super.get(`/disconnect/instance/${id}`);
  }

  syncContactsByChannel(id: string): Observable<Channel> {
    return super.get(`/syncContacts/instance/${id}`);
  }

  getByAllChannel(): Observable<Channel[]> {
    return super.get(`/list`);
  }

  override getList(name?: string): Observable<Channel[]> {
    const params = name ? { params: { name } } : {};
    return this.http.get<Channel[]>(this.getURLBase() + '/list', params);
  }

  getByIdAndMetrics(id: string, phoneNumber: string): Observable<Array<{ sentMessages: number, receivedMessages: number, totalAttendance: number, totalMessages: number, totalContacts: number }>> {
    return super.get(`/byId/${id}?phoneNumber=${phoneNumber}`);
  }
}
