import { Base } from "./base.model";
import { Channel } from "./channel.model";
import { Company } from "./company.model";
import { Contact } from "./contact.model";
import { Department } from "./department.model";
import { AttendanceStatusEnum } from "./enum/attendance.status.enum";
import { Message } from "./message.model";
import { Tag } from "./tag.model";
import { User } from "./user.model";

export interface Attendance extends Base {
  protocol: string;
  contact: Contact;
  endDate: Date;
  company: Company;
  channel: Channel;
  user: User;
  lastMessage: Message;
  department: Department;
  status: AttendanceStatusEnum;
  tags: Tag[];
  inConversation: boolean;
  expiredAt: Date;
  countUnreadMessages: number;
  chatbotStatus: {
    automated: boolean;
    running: boolean;
    expiredAt: Date;
  };
  kpis: KpisTypes;
  closedAt: Date;
}

export interface KpisTypes {
  tmfh: number;
  tme: number;
  tma: number;
  tmp: number;
  tmta: number;
};
