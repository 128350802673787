import { Component, Inject, Injector, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractComponent } from '../../../commons/abstract.component';
import { MustMatch, PublicUserService, UserService } from '../../../../public-api';

@Component({
  templateUrl: 'reset-password.component.html',
})
export class ResetPasswordComponent extends AbstractComponent implements OnInit {

  hideAllPasswords: boolean = false

  hide: boolean = true;
  userId: string;

  constructor(
    injector: Injector,
    private publicUserService: PublicUserService,
    private userService: UserService,
    private dialogRef: MatDialogRef<ResetPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { userId: string }
  ) {
    super(injector);
    this.setupForm();
    this.userId = data.userId
  }

  ngOnInit() {
  }

  setupForm(): void {
    this.formGroup = this.formBuilder.group({
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmedNewPassword: ['', [Validators.required, Validators.minLength(6)]],
    }, { validators: [MustMatch('newPassword', 'confirmedNewPassword')] });
  }

  save(): void {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }
    const { newPassword, confirmedNewPassword } = this.formGroup.value;

    if (!this.userId) {
      return;
    }
    this.loading = true;
    this.userService.resetPassword(this.userId, { newPassword, confirmedNewPassword: confirmedNewPassword })
      .subscribe({
        next: () => {
          this.dialogRef.close();
          this.alertService.success();
        },
        complete: () => this.loading = false
      });
  }

  togglePasswordVisibility(): void {
    this.hideAllPasswords = !this.hideAllPasswords
  }

}

