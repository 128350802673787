export enum WebhookIntegrationItemType {
  WHATSAPP_PHONE = "WHATSAPP_PHONE",
  CONTACT_NAME = "CONTACT_NAME",
  ADD_TAG = "ADD_TAG",
  REMOVE_TAG = "REMOVE_TAG",
  START_ATTENDANCE = "START_ATTENDANCE",
  SEND_MESSAGE = "SEND_MESSAGE",
  ADD_TEMPLATE = "ADD_TEMPLATE",
}



export enum WebhookIntegrationItemTypeTranslate {
  WHATSAPP_PHONE = "Telefone Whatsapp",
  CONTACT_NAME = "Criar/Atualizar Nome do Contato",
  ADD_TAG = "Adicionar Etiqueta",
  REMOVE_TAG = "Remover Etiqueta",
  START_ATTENDANCE = "Iniciar Atendimento",
  SEND_MESSAGE = "Enviar Mensagem",
  ADD_TEMPLATE = "Adicionar Template",
}